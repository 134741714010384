import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from 'styled-components'
import TipForm from "../components/form"
import bgimage from '../images/banner-images/submit-tip.jpg'

class TipPage extends React.Component {
  render(props) {

    return (
      <Layout>
      <SEO title="Submit a tip to Snaphints - submit some photography hints and tips to share" image={bgimage} />

       <Banner>
          <Container>
            <h1>Submit a tip</h1>
          </Container>
        </Banner>

      <Main>
      
        <p>Do you have a photography tip which you would like to share with others?</p>
        <p>You can submit your tip using the below form, we'll review and publish on the site. You'll also get your own profile!</p>

        <TipForm formid="xknqnoko" messagelabel="Tip" />

      </Main>
      
      </Layout>
    )
  }
}

export default TipPage

const Banner = styled.section`
  background-image: url(${bgimage});
  height: 300px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 20%;
  margin-bottom: 40px;
`

const Container = styled.div`
  max-width: 800px;
   margin: auto; 
   display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  padding-bottom: 40px;

  h1 {
    font-size: 30px;
  }

  @media(max-width: 768px) {
    padding-left: 20px;
  }
`

const Main = styled.div`
  max-width: 800px;
  margin: 60px auto; 
  color: #fff;

  @media(max-width: 768px) {
    padding: 0 20px;
    margin-top: 0;
  }

  form {
  	display: flex;
    flex-direction: column;

    input {
    	margin-bottom: 20px;
    	font-size: 16px;
    	padding: 5px;

    	&:focus {
    		outline-color: #3c7db6;
    	}
    }

    textarea {
    	min-height: 100px;
    }

    button {
    	margin-top: 20px;
    	display: flex;
    	align-self: flex-end;
    	background: #3c7db6;
    	border: 1px solid #3c7db6;
    	padding: 10px 15px;
    	margin-left: 20px;
    	font-size: 16px;
    	color: #fff;

      @media screen and (max-width: 479px) {
        width: 100%;
        text-align: center;
        display: block;
      }
    }
  }
`